<template>
  <div>
    <Title title="FAQ" />
    <div>
      <AccordionItem>
        <template v-slot:heading>CAN ANYONE LEARN TO FLY?</template>
        <template v-slot:description>Yes! That's the beauty of recreational aviation — literally anyone can learn to fly. If you have a health standard equivalent to driving a car, you can learn to fly in one of our Eurofoxes.</template>
      </AccordionItem>
      <AccordionItem>
        <template v-slot:heading>DO MY HOURS UNDER RA-AUS COUNT FOR A RPL, PPL OR CPL?</template>
        <template v-slot:description>Yes. Every flying hour you conduct in an RA-Aus registered aircraft counts towards a CASA Flight Crew Licence. <br><br> In fact, we'd encourage you to start with RA-Aus first as it's significantly cheaper than your typical Cessna 172 and flying RA-Aus aircraft places a real emphasis on the stick and rudder skills that are somewhat degraded in heavier aircraft.</template>
      </AccordionItem>
      <AccordionItem>
        <template v-slot:heading>WHAT IF I WEAR GLASSES? DOES THAT STOP ME LEARNING?</template>
        <template v-slot:description>You can drive a car wearing glasses, right? The same thing applies with aviation. Even airline captains wear glasses. As long as your vision is correctable to an acceptable standard, you'll be right to fly.</template>
      </AccordionItem>
      <AccordionItem>
        <template v-slot:heading>HOW OLD DO I HAVE TO BE?</template>
        <template v-slot:description>While you can learn to fly from any age, we generally don't recommend starting before 14 years of age.</template>
      </AccordionItem>
      <AccordionItem>
        <template v-slot:heading>ALRIGHT, I'M KEEN. HOW DO I START?</template>
        <template v-slot:description>Come in to our office at Archerfield or call us on <a href="tel:0732727707">(07) 3272 7707</a>. We don't need a formal interview with the Chief Flying Instructor like some other schools do, you can make a booking and start learning to fly that very day.</template>
      </AccordionItem>
    </div>
  </div>
</template>

<script>
import AccordionItem from '@/components/AccordionItem.vue'
import Title from '@/components/Title.vue'

export default {
  components: {
    AccordionItem,
    Title
  }
}
</script>
